import React from 'react';
import { MediaAsset } from '../../../types/media-management.type';

import '../styles/preview-image.scss';

interface Props {
    asset: MediaAsset;
}

const MediaManagementAssetTilePreviewImage = ({ asset }: Props) => {
    return (
        <div className="media-management-asset-tile-preview-image">
            <img src={asset.data.files?.[0]?.thumbnail?.url} className="media-management-asset-tile-preview-image__image" alt={asset.title} />
        </div>
    );
};

export default MediaManagementAssetTilePreviewImage;
