import React from 'react';
import CopyMultiLanguage from 'components/input/CopyMultiLanguage';
import SelectInput from 'components/input/SelectInput';
import RadioList from 'components/input/RadioList';

interface Props {
    value: { [key: string]: { value: string } } | null;
    options: { [key: string]: string } | { key: string; value: string }[] | string[];
    sortOptions?: boolean;
    onMutation: (value: { [key: string]: { value: string } } | null) => void;
    setFirstOption?: boolean;
    showEmptyOption?: boolean;
    readOnly?: boolean;
    multiline?: boolean;
    language: string;
    maxLength?: number;
    selectType?: 'select' | 'radioList';
}

const TextSelectMultiLanguage = ({
    value,
    options,
    sortOptions,
    onMutation,
    setFirstOption,
    showEmptyOption,
    readOnly,
    multiline,
    language,
    maxLength,
    selectType = 'select'
}: Props): JSX.Element => {
    const filteredOptions: Props['options'] = (() => {
        if (Array.isArray(options)) {
            return options.filter((option) => {
                if (typeof option === 'string') {
                    return !!option;
                } else {
                    return !!option.key;
                }
            });
        } else {
            const optionsWithValues = Object.keys(options).filter((option) => !!options[option]);
            const newFilteredOptions = {};
            optionsWithValues.forEach((option) => {
                newFilteredOptions[option] = options[option];
            });

            return newFilteredOptions;
        }
    })();

    const onChange = (newValue: string) => {
        onMutation({ ...value, [language]: { value: newValue } });
    };

    return (
        <div className="input__field__text-select">
            {selectType === 'select' && (
                <SelectInput
                    value={value?.[language]?.value}
                    options={filteredOptions}
                    sortOptions={sortOptions}
                    onMutation={onChange}
                    setFirstOption={setFirstOption}
                    showEmptyOption={showEmptyOption}
                    readOnly={readOnly}
                />
            )}
            {selectType === 'radioList' && <RadioList value={value?.[language]?.value} options={filteredOptions} onMutation={onChange} />}
            <CopyMultiLanguage value={value} onMutation={onMutation} multiline={multiline} readOnly={readOnly} language={language} maxLength={maxLength} />
        </div>
    );
};

export { TextSelectMultiLanguage };
