import React from 'react';
import Illustration, { IllustrationProps } from 'components/ui-components-cape/Illustration';

import '../styles/preview-other.scss';

interface Props {
    icon?: IllustrationProps['illustration'];
}

const MediaManagementAssetTilePreviewOther = ({ icon = 'fallback' }: Props) => {
    return (
        <div className="media-management-asset-tile-preview-other">
            <Illustration illustration={icon} />
            {/* Clicking the SVG sometimes doesn't always trigger the click event, so we add a div to catch the click event */}
            <div className="media-management-asset-tile-preview-other__clickcatch" />
        </div>
    );
};

export default MediaManagementAssetTilePreviewOther;
