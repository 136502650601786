import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Switch from 'components/ui-components-v2/Switch';

/**
 * SwitchInput
 * Displays a switch in the interface.
 */
export default class SwitchInput extends Component {
    static propTypes = {
        /** Function which gets called when the switch has changed */
        onMutation: PropTypes.func,
        /** Current value of the picker */
        value: PropTypes.any,
        //**Severity of the switch */
        severity: PropTypes.string
    };

    static defaultProps = {
        onMutation: () => {},
        severity: 'primary'
    };

    constructor(props) {
        super();

        this.state = {
            value: props.value ? true : false
        };
    }

    /**
     * OnChange
     * Change the input of the switch, so set state and mutate.
     */
    onChange = (event) => {
        event.stopPropagation();

        const { onMutation, inverted = false } = this.props;
        const value = inverted ? !event.target.checked : event.target.checked;

        onMutation(value);
        this.setState({ value });
    };

    /**
     * Component updated.
     * Check whether the props require a new state.
     */
    componentDidUpdate = (prevProps) => {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value });
        }
    };

    render() {
        const { severity, inverted = false } = this.props;
        const { value = false, readOnly = false } = this.state;
        // This broader definition of boolean is for feed values that are a bit unpredictable.
        const actualValue = (() => {
            if (value === 'true' || value === '1' || value === 1) return true;
            if (value === 'false' || value === '0' || value === 0) return false;
            return value;
        })();

        return <Switch onChange={this.onChange} checked={inverted ? !actualValue : actualValue} disabled={readOnly} color={severity} />;
    }
}
