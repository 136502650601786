import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'components/ui-components-v2/Select';
import Request from 'components/data/Request';
import EditorData from 'components/editor-data/EditorData';
import Loader from 'components/ui-components/Loader';
import '../styles/main.scss';

/**
 * SubAccounts
 * This input components allows the selection of a subaccount
 */
export class SubAccounts extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func
    };

    static defaultProps = {
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : 0,
            loading: true
        };

        // We have not a value in the campaign, use the value from the campaign itself
        if (!this.state.value) {
            this.state.value = EditorData.get('subAccountId');
        }
    }

    componentDidMount() {
        this.loadList();
    }

    loadList() {
        // Fetch a token to connect to the publish api
        Request.post('subAccounts/listAll', {}).then((result) => {
            this.setState({ loading: false, list: result.data });
        });
    }

    /**
     * Value changed
     * Send to onMutation
     */
    onChanged = (event) => {
        const { onMutation } = this.props;
        const value = event.target.value;
        this.setState({ value: value });
        onMutation(value);
    };

    render() {
        const { loading, list, value } = this.state;
        return (
            <div className="input__sub-accounts">
                {loading && <Loader />}

                {!loading && list && (
                    <Select native value={value} onChange={this.onChanged} variant="outlined" fullWidth margin="dense">
                        <option value={0} key={'none'}>
                            No subaccount
                        </option>
                        {list &&
                            list.map((item, index) => (
                                <option value={item.id} key={index}>
                                    {item.title}
                                </option>
                            ))}
                    </Select>
                )}
            </div>
        );
    }
}

export default SubAccounts;
