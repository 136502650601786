import React from 'react';
import Copy from 'components/input/Copy';
import SelectInput from 'components/input/SelectInput';
import RadioList from 'components/input/RadioList';
import '../styles/index.scss';

interface Props {
    value: string;
    options: { [key: string]: string } | { key: string; value: string }[] | string[];
    sortOptions?: boolean;
    onMutation: (value: string) => void;
    setFirstOption?: boolean;
    showEmptyOption?: boolean;
    readOnly?: boolean;
    multiline?: boolean;
    maxLength?: number;
    selectType?: 'select' | 'radioList';
}

const TextSelect = ({
    value,
    options,
    sortOptions,
    onMutation,
    setFirstOption,
    showEmptyOption,
    readOnly,
    multiline,
    maxLength,
    selectType = 'select'
}: Props): JSX.Element => {
    const filteredOptions: Props['options'] = (() => {
        if (Array.isArray(options)) {
            return options.filter((option) => {
                if (typeof option === 'string') {
                    return !!option;
                } else {
                    return !!option.key;
                }
            });
        } else {
            const optionsWithValues = Object.keys(options).filter((option) => !!options[option]);
            const newFilteredOptions = {};
            optionsWithValues.forEach((option) => {
                newFilteredOptions[option] = options[option];
            });

            return newFilteredOptions;
        }
    })();

    return (
        <div className="input__field__text-select">
            {selectType === 'select' && (
                <SelectInput
                    value={value}
                    options={filteredOptions}
                    sortOptions={sortOptions}
                    onMutation={onMutation}
                    setFirstOption={setFirstOption}
                    showEmptyOption={showEmptyOption}
                    readOnly={readOnly}
                />
            )}
            {selectType === 'radioList' && <RadioList value={value} options={filteredOptions} onMutation={onMutation} />}
            <Copy value={value} useValueObject={false} onMutation={onMutation} multiline={multiline} readOnly={readOnly} maxLength={maxLength} />
        </div>
    );
};

export { TextSelect };
