import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AssetV2 } from 'types/asset.type';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import { downloadMediaAssets } from 'components/media-management/helpers/download-media-assets';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onClose: () => void;
    onSetWaiting: (waiting: boolean) => void;
}

const MediaManagementAssetMenuItemsDownload = ({ asset, onSetWaiting, onClose }: Props) => {
    return (
        <MenuItem
            onClick={(e) => {
                e.stopPropagation();
                downloadMediaAssets([asset._id], onSetWaiting);
                onClose();
            }}>
            <ListItemIcon>
                <Icon>download</Icon>
            </ListItemIcon>
            <ListItemText>{Translation.get('actions.downloadFile', 'media-management')}</ListItemText>
        </MenuItem>
    );
};

export default MediaManagementAssetMenuItemsDownload;
