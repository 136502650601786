import React from 'react';
import { MediaAsset } from '../../../types/media-management.type';

import '../styles/preview-font.scss';

interface Props {
    asset: MediaAsset;
}

const MediaManagementAssetTilePreviewFont = ({ asset }: Props) => {
    return (
        <div className="media-management-asset-tile-preview-font">
            <img src={asset.data.files?.[0]?.thumbnail?.url} className="media-management-asset-tile-preview-font__image" alt={asset.title} />
        </div>
    );
};

export default MediaManagementAssetTilePreviewFont;
