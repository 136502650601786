import React, { useEffect, useState } from 'react';
import { MEDIA_MANAGEMENT_CONFIG, MEDIA_MANAGEMENT_DEFAULT_SETUP } from 'components/media-management/constants';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import Loader from 'components/ui-components/Loader';
import AssetManagementHelpers from 'components/asset-management/helpers';
import AssetManagementConfigProvider from 'components/asset-management/context/asset-management-config.context';
import AssetManagementSelector from 'components/asset-management/components/selector';
import { AssetManagementConfigEnriched } from 'components/asset-management/types/asset-management-config.type';
import { MediaSelectorProps } from 'components/media-management/types/media-selector-props.type';
import { MediaAsset } from 'components/media-management/types/media-management.type';
import MediaManagementSelectorTile from './tile';

type Props = MediaSelectorProps;

const { storeName, type, setupResource } = MEDIA_MANAGEMENT_CONFIG;

const MediaManagementSelector = ({ dataFilters, subType, collectionQuery, objectName, objectId, onSelect }: Props) => {
    const [enrichedConfig, setEnrichedConfig] = useState<AssetManagementConfigEnriched | null>(null);

    //Initialize the Component Store for Media Management so we have acces to the filtersetup.
    useEffect(() => {
        const initManagement = async () => {
            const setup = await AssetManagementHelpers.getAssetManagementSetup(setupResource, MEDIA_MANAGEMENT_DEFAULT_SETUP);
            const enrichedConfig = await AssetManagementHelpers.getEnrichedConfig(MEDIA_MANAGEMENT_CONFIG, setup);
            if (enrichedConfig) setEnrichedConfig(enrichedConfig);
        };
        if (!ComponentStoreHelpers.get(storeName)) {
            AssetManagementHelpers.init(MEDIA_MANAGEMENT_CONFIG);
        }
        initManagement();
    }, []);

    return (
        <Loader isLoading={!enrichedConfig}>
            <AssetManagementConfigProvider config={enrichedConfig as AssetManagementConfigEnriched}>
                <AssetManagementSelector
                    onSelect={(item) => onSelect(item as MediaAsset)}
                    dataFilters={dataFilters}
                    subType={subType}
                    collectionQuery={collectionQuery}
                    objectName={objectName}
                    objectId={objectId}
                    type={type}
                    assetTile={<MediaManagementSelectorTile />}
                />
            </AssetManagementConfigProvider>
        </Loader>
    );
};

export default MediaManagementSelector;
