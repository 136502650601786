import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import LWFiles from 'components/data/Files';
import Translation from 'components/data/Translation';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { findAssetInStore } from 'components/asset-management/utilities';
import { MediaAsset } from '../types/media-management.type';

const transformFileName = (fileName: string) => fileName.replace(/\.[^/.]+$/, '.zip');

const downloadMediaAssets = async (itemIds: string[], onSetWaiting: (waiting: boolean) => void): Promise<void> => {
    onSetWaiting(true);
    SnackbarUtils.info(Translation.get('snackbar.preparingDownload', 'media-management'));

    const { url } = await AssetManagementService.assetDownload(itemIds);

    // if only one asset is selected, and we can find it in the store we can download it directly otherwise
    // it is a collection which should be a zip file
    const fileName = await (async () => {
        if (itemIds.length === 1) {
            const foundAsset = findAssetInStore(itemIds[0], 'MediaManagement');
            const foundFileName = foundAsset ? (foundAsset as MediaAsset)?.data.files?.[0].fileName : undefined;
            if (foundFileName) return transformFileName(foundFileName);

            const fetchedAsset = await AssetManagementService.getAsset(itemIds[0]);
            const fetchedFileName = fetchedAsset ? (fetchedAsset as MediaAsset)?.data.files?.[0].fileName : undefined;
            if (fetchedFileName) return transformFileName(fetchedFileName);

            return 'unknown.zip';
        }
        return 'asset-selection.zip';
    })();

    if (url && fileName) {
        SnackbarUtils.success(Translation.get('snackbar.downloadStared', 'media-management'));
        LWFiles.downloadFileFromUrl(url, fileName, () => onSetWaiting(false));
        return;
    }
};

export { downloadMediaAssets };
