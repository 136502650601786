import { MediaSubType } from '../types/media-sub-type.type';

// Match file types that can be set as a Prop on the AssetGalleryInput component to their respective subtypes.
const mapFileTypesToSubTypes = (fileType: string | string[]): MediaSubType[] => {
    const fileTypeArray = Array.isArray(fileType) ? fileType : [fileType];
    const subTypes: MediaSubType[] = [];

    const getSubType = (type: string): MediaSubType | null => {
        switch (type) {
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'tif':
            case 'png':
            case 'raw':
            case 'svg':
            case 'webp':
            case 'bmp':
            case 'heif':
            case 'image':
            case 'imagegif':
                return 'image';
            case 'mp4':
            case 'mov':
            case 'webm':
            case 'avi':
            case 'f4v':
            case 'm4v':
            case 'video':
                return 'video';
            case 'mp3':
            case 'wav':
            case 'wma':
            case 'aac':
            case 'flac':
            case 'm4a':
            case 'mpa':
            case 'aif':
            case 'audio':
                return 'audio';
            case 'ttf':
            case 'otf':
            case 'eot':
            case 'woff':
            case 'woff2':
            case 'font':
                return 'font';
            case 'html':
            case 'htm':
            case 'txt':
            case 'csv':
            case 'js':
            case 'css':
            case 'less':
            case 'srt':
            case 'xml':
            case 'json':
            case 'text':
                return 'text';
            case 'pdf':
                return 'pdf';
            case 'eps':
            case 'psd':
            case 'ai':
            case 'indd':
            case 'doc':
            case 'docx':
            case 'xls':
            case 'xlsx':
            case 'ppt':
            case 'pptx':
            case 'document':
                return 'document';
            case 'zip':
            case 'gz':
            case 'archive':
                return 'zip';
            case 'lottie':
                return 'lottie';
            default:
                return null;
        }
    };

    fileTypeArray.forEach((type) => {
        const subType = getSubType(type);
        if (!subType) return;
        if (subTypes.includes(subType)) return;
        subTypes.push(subType);
    });

    return subTypes;
};

export { mapFileTypesToSubTypes };
