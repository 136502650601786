import { ImageData } from 'components/media-management/types/media-image-data.type';

const getFocusPointCoordinates = (e: React.DragEvent<HTMLDivElement>, imageData: ImageData): { x: number; y: number } => {
    const { imageLeft, imageTop, scale } = imageData;
    const { clientX, clientY } = e;

    const x = Math.floor((clientX - imageLeft) / scale);
    const y = Math.floor((clientY - imageTop) / scale);

    const coordinates = { x, y };
    return coordinates;
};

export { getFocusPointCoordinates };
