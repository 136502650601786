import React from 'react';
import { MediaAsset } from '../../../types/media-management.type';

import '../styles/preview-text.scss';

interface Props {
    asset: MediaAsset;
}

const MediaManagementAssetTilePreviewText = ({ asset }: Props) => {
    return (
        <div className="media-management-asset-tile-preview-text">
            <div className="media-management-asset-tile-preview-text__sheet">
                <img src={asset.data.files?.[0]?.thumbnail?.url} className="media-management-asset-tile-preview-text__image" alt={asset.title} />
            </div>
        </div>
    );
};

export default MediaManagementAssetTilePreviewText;
