import '../styles/main.scss';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

/**
 * Transforms field
 * Allows you to set x and y offset.
 */
export default class Transforms extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.object,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func
    };

    static defaultProps = {
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.value != prevProps.value) {
            this.setState({ value: this.props.value });
        }
    };

    onChange = (event, field) => {
        const value = { ...this.state.value };
        value[field] = parseInt(event.target.value);
        this.setState({ value: value });
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.updateModel, 300);
    };

    updateModel = () => {
        const { onMutation } = this.props;
        onMutation({
            ...this.props.value,
            ...this.state.value
        });
    };

    render() {
        const { value = {} } = this.state;

        return (
            <div className="input__field__transforms">
                <div className="input__field__transforms__item">
                    <FormControl variant="outlined">
                        <InputLabel>Left</InputLabel>
                        <OutlinedInput
                            type="number"
                            variant="outlined"
                            labelWidth={60}
                            value={value.left ? value.left : ''}
                            onChange={(e) => this.onChange(e, 'left')}
                            endAdornment={<InputAdornment position="end">px</InputAdornment>}
                        />
                    </FormControl>
                </div>
                <div className="input__field__transforms__item">
                    <FormControl variant="outlined">
                        <InputLabel>Top</InputLabel>
                        <OutlinedInput
                            type="number"
                            variant="outlined"
                            labelWidth={60}
                            value={value.top ? value.top : ''}
                            onChange={(e) => this.onChange(e, 'top')}
                            endAdornment={<InputAdornment position="end">px</InputAdornment>}
                        />
                    </FormControl>
                </div>
            </div>
        );
    }
}
