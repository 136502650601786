import React from 'react';
import { MediaAsset } from '../../../types/media-management.type';
import MediaManagementAssetTilePreviewImage from './preview-image';
import MediaManagementAssetTilePreviewText from './preview-text';
import MediaManagementAssetTilePreviewAudio from './preview-audio';
import MediaManagementAssetTilePreviewLottie from './preview-lottie';
import MediaManagementAssetTilePreviewVideo from './preview-video';
import MediaManagementAssetTilePreviewFont from './preview-font';
import MediaManagementAssetTilePreviewOther from './preview-other';

interface Props {
    asset: MediaAsset;
}

const MediaManagementAssetTilePreview = ({ asset }: Props) => {
    switch (asset.subType) {
        case 'text':
        case 'pdf':
            return <MediaManagementAssetTilePreviewText asset={asset} />;
        case 'zip':
            return <MediaManagementAssetTilePreviewOther icon="zip" />;
        case 'video':
            return <MediaManagementAssetTilePreviewVideo asset={asset} />;
        case 'audio':
            return <MediaManagementAssetTilePreviewAudio asset={asset} />;
        case 'font':
            return <MediaManagementAssetTilePreviewFont asset={asset} />;
        case 'lottie':
            return <MediaManagementAssetTilePreviewLottie asset={asset} />;
        case 'image':
        case 'design_file':
            return <MediaManagementAssetTilePreviewImage asset={asset} />;
        default:
            return <MediaManagementAssetTilePreviewOther />;
    }
};

export default MediaManagementAssetTilePreview;
