import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { MediaAsset } from '../../../types/media-management.type';

import '../styles/preview-video.scss';

interface Props {
    asset: MediaAsset;
}

const MediaManagementAssetTilePreviewVideo = ({ asset }: Props) => {
    const [videoLoading, setVideoLoading] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const isMounted = useRef(false);

    const url = asset.data.files?.[0]?.preview?.url;

    useEffect(() => {
        isMounted.current = true;
        return () => {
            // If the video is unmouted while playing or waiting for play we get an error.
            if (videoRef.current && !videoRef.current.paused) videoRef.current.pause();
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const video = videoRef.current;

        const handleCanPlay = () => {
            setVideoLoading(false);
        };

        const handleLoadStart = () => {
            if (url) setVideoLoading(true);
        };

        if (video) {
            video.addEventListener('loadstart', handleLoadStart);
            video.addEventListener('canplay', handleCanPlay);
        }

        return () => {
            if (video) {
                video.addEventListener('loadstart', handleLoadStart);
                video.removeEventListener('canplay', handleCanPlay);
            }
        };
    }, [videoRef]);

    const togglePlay = async () => {
        const video = videoRef.current;

        if (video) {
            if (video.paused) {
                try {
                    await video.play();
                } catch (e) {
                    // If the video is unmouted while playing or waiting for play we get an error.
                }
            } else {
                video.pause();
            }
        }
    };

    return (
        <div className={classNames('media-management-asset-tile-preview-video', { 'media-management-asset-tile-preview-video--loading': videoLoading })}>
            <video
                key={`video-tile-${asset._id}`}
                ref={videoRef}
                poster={asset.data.files?.[0]?.thumbnail?.url}
                width={'100%'}
                preload="none"
                muted
                loop
                disableRemotePlayback
                onMouseEnter={togglePlay}
                onMouseLeave={togglePlay}
                className="media-management-asset-tile-preview-video__video">
                <source src={url} type="video/mp4" />
            </video>
        </div>
    );
};

export default MediaManagementAssetTilePreviewVideo;
