import { MEDIA_MANAGEMENT_CONFIG } from '../constants';

// Match file types that can be set as a Prop on the AssetGalleryInput component to accepted extentions in Media Management.
const mapFileTypesToExtentions = (fileType: string | string[]): string[] => {
    const fileTypeArray = Array.isArray(fileType) ? fileType : [fileType];
    const extensions: string[] = [];

    fileTypeArray.forEach((type) => {
        if (!MEDIA_MANAGEMENT_CONFIG.acceptedFileTypes) return;
        if (extensions.includes(type.toLowerCase())) return;
        if (MEDIA_MANAGEMENT_CONFIG.acceptedFileTypes.includes(`.${type.toLowerCase()}`)) {
            extensions.push(type.toLowerCase());
        }
    });

    return extensions;
};

export { mapFileTypesToExtentions };
