// The default setup is used when no custom setup is present in the resources.

import { AssetManagementSetup } from 'components/asset-management/types/asset-management-setup.type';

export const MEDIA_MANAGEMENT_DEFAULT_SETUP: AssetManagementSetup = {
    assetFields: [
        {
            name: 'Media title',
            key: 'title',
            type: 'textfield',
            editable: true,
            filterable: false,
            required: true
        },
        {
            name: 'Collection',
            key: 'collections',
            type: 'selectMultiple',
            editable: true,
            filterable: false,
            required: false
        },
        {
            name: 'Brands',
            key: 'brands',
            type: 'selectMultiple',
            editable: true,
            filterable: true,
            required: false
        },
        {
            name: 'Departments',
            key: 'departments',
            type: 'selectMultiple',
            editable: true,
            filterable: true,
            required: false
        },
        {
            name: 'Markets',
            key: 'markets',
            type: 'selectMultiple',
            editable: true,
            filterable: true,
            required: false
        },
        {
            name: 'File Type',
            key: 'subType',
            type: 'selectMultiple',
            editable: false,
            filterable: true,
            required: false
        },
        {
            name: 'Tags',
            key: 'tags',
            type: 'selectMultiple',
            editable: true,
            filterable: true,
            required: false,
            optionsFromBackEnd: true
        },
        {
            name: 'Auto tags',
            key: 'vision_tags',
            type: 'selectMultiple',
            editable: false,
            filterable: true,
            required: false,
            optionsFromBackEnd: true
        },
        {
            name: 'Dominant colors',
            key: 'dominant_colors',
            type: 'selectMultiple',
            editable: false,
            filterable: true,
            required: false,
            optionsFromBackEnd: true
        }
    ],
    assetFieldsGrouping: [
        {
            title: 'General',
            icon: 'title',
            open: true,
            inputs: ['title']
        },
        {
            title: 'Availability',
            icon: 'lock_open',
            open: true,
            inputs: ['brands', 'markets', 'departments']
        },
        {
            title: 'Details',
            icon: 'new_label',
            open: true,
            inputs: ['tags', 'collections']
        }
    ],
    assetFilterOrder: ['subType', 'brands', 'markets', 'departments', 'tags', 'vision_tags', 'dominant_colors'],
    defaultPublic: true,
    cropFormats: {
        skyscraper_160x600: {
            width: 160,
            height: 600,
            title: 'Skyscraper 160x600'
        },
        medium_rectangle_300x250: {
            width: 300,
            height: 250,
            title: 'Medium Rectangle 300x250'
        },
        leaderboard_728x90: {
            width: 728,
            height: 90,
            title: 'Leaderboard 728x90'
        },
        half_page_300x600: {
            width: 300,
            height: 600,
            title: 'Half Page 300x600'
        },
        mobile_leaderboard_320x50: {
            width: 320,
            height: 50,
            title: 'Mobile Leaderboard 320x50'
        },
        billboard_970x250: {
            width: 970,
            height: 250,
            title: 'Billboard 970x250'
        },
        square_250x250: {
            width: 250,
            height: 250,
            title: 'Square 250x250'
        },
        small_square_200x200: {
            width: 200,
            height: 200,
            title: 'Small Square 200x200'
        },
        full_banner_468x60: {
            width: 468,
            height: 60,
            title: 'Full Banner 468x60'
        },
        large_rectangle_336x280: {
            width: 336,
            height: 280,
            title: 'Large Rectangle 336x280'
        },
        wide_skyscraper_120x600: {
            width: 120,
            height: 600,
            title: 'Wide Skyscraper 120x600'
        },
        vertical_rectangle_240x400: {
            width: 240,
            height: 400,
            title: 'Vertical Rectangle 240x400'
        },
        large_leaderboard_970x90: {
            width: 970,
            height: 90,
            title: 'Large Leaderboard 970x90'
        },
        portrait_300x1050: {
            width: 300,
            height: 1050,
            title: 'Portrait 300x1050'
        },
        large_mobile_banner_320x100: {
            width: 320,
            height: 100,
            title: 'Large Mobile Banner 320x100'
        },
        vertical_banner_120x240: {
            width: 120,
            height: 240,
            title: 'Vertical Banner 120x240'
        },
        half_banner_234x60: {
            width: 234,
            height: 60,
            title: 'Half Banner 234x60'
        },
        micro_bar_88x31: {
            width: 88,
            height: 31,
            title: 'Micro Bar 88x31'
        },
        pushdown_970x66: {
            width: 970,
            height: 66,
            title: 'Pushdown 970x66'
        },
        mobile_medium_rectangle_300x50: {
            width: 300,
            height: 50,
            title: 'Mobile Medium Rectangle 300x50'
        },
        mobile_large_rectangle_300x100: {
            width: 300,
            height: 100,
            title: 'Mobile Large Rectangle 300x100'
        },
        triple_widescreen_250x360: {
            width: 250,
            height: 360,
            title: 'Triple Widescreen 250x360'
        },
        netboard_580x400: {
            width: 580,
            height: 400,
            title: 'Netboard 580x400'
        },
        wide_square_640x480: {
            width: 640,
            height: 480,
            title: 'Wide Square 640x480'
        }
    }
};
