import { AssetSubType } from 'types/asset.type';
import User from 'components/data/User';
import Params from '../components/asset-share-dialog/types/params.type';

// Construct a share url for an asset.
const getShareUrl = (fileId: string | undefined, params: Params, version: 1 | 2, fileUrl?: string, subType?: AssetSubType): string => {
    if (subType === 'image' && fileId) {
        const accountId = User.get('accountId');
        const scaleEndPoint = version === 1 ? process.env.IMAGE_SCALER_URL_V1 : process.env.IMAGE_SCALER_URL;
        const shareUrl = `${scaleEndPoint}account-${accountId}/${fileId}`;

        if (Object.keys(params).length) params = { ...params, ts: Date.now().toString() };
        const queryString = Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&');
        if (queryString.length > 0) {
            return `${shareUrl}?${queryString}`;
        }
        return shareUrl;
    }
    return fileUrl || '';
};

export { getShareUrl };
