import React from 'react';
import classNames from 'classnames';

import '../styles/info.scss';

interface Props {
    title: string;
    subtitle: string;
    small?: boolean;
}

const MediaManagementAssetTileInfo = ({ title, subtitle, small }: Props) => {
    return (
        <div className="media-management-asset-tile-info">
            <div className={classNames('media-management-asset-tile-info__title', { 'media-management-asset-tile-info__title--small': small })}>{title}</div>
            <div className="media-management-asset-tile-info__subtitle">{subtitle}</div>
        </div>
    );
};

export default MediaManagementAssetTileInfo;
